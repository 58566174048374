<template>
    <input type="checkbox" v-model.lazy="field.$model" :disabled="disabled" :id="id" />
    <label :for="id" class="d-inline ms-1">{{ label }}</label>
</template>

<script>
    export default {
        props: {
            field: { type: Object, required: true },
            label: { type: String, required: false },
            disabled: { type: Boolean, required: false, default: false },
            id: { type: String, required: false, default: null }
        }
    };
</script>
