// NOTE: regexps are duplicated on the back-end in Constants.cs file

const localPhoneNumberNo = (value) =>
    isNaN(value) || isNaN(parseFloat(value)) || new RegExp(`^[2-9][0-9]{7}$`).test(value);

const localPhoneNumberSe = (value) =>
    isNaN(value) || isNaN(parseFloat(value)) || new RegExp(`^0[1-9][0-9]{6,8}$`).test(value);

const GetLocalPhoneRule = (appLocale) => {
    switch (appLocale) {
        case 'No':
            return localPhoneNumberNo;

        case 'Sv':
            return localPhoneNumberSe;

        default:
            return null;
    }
};

export default {
    GetLocalPhoneRule
};
