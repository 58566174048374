<template>
    <span class="invalid-feedback" v-for="error of formModel.$errors" :key="error.$uid">
        {{ error.$message }}
    </span>
</template>

<script>
    // 1. formModel: like 'v$.phoneNumber'
    // 2. helpers.withMessage should be used to define a localized message, otherwise default one would be used
    export default {
        props: {
            formModel: { type: Object, required: true }
        }
    };
</script>
