<template>
    <Spinner v-if="INITIATING_PAYMENT" :overlayParent="true" />

    <ErrorMessage :message="INITIATION_ERROR" />

    <form novalidate @submit.prevent="submitForm()" class="col-sm-8 offset-sm-2 text-center">
        <div class="mb-3">
            <p class="fw-bold text-start">{{ t('gift_card.minutes') }}</p>
            <div class="ms-3" v-for="tariff of TARIFFS" :key="tariff.minutes" style="text-align: left;">
                <input
                    type="radio"
                    :id="tariff.minutes"
                    :value="tariff.minutes"
                    v-model="v$.minutes.$model"
                    :disabled="disableForm"
                />
                <label :for="tariff.minutes" class="d-inline" :disabled="disableForm"
                    ><span v-if="tariff.defaultValue" class="ms-1">{{
                        t('gift_card.minutes_option_default', {
                            price: tariff.price,
                            minutes: tariff.minutes
                        })
                    }}</span>
                    <span v-else class="ms-1">{{
                        t('gift_card.minutes_option', {
                            price: tariff.price,
                            minutes: tariff.minutes
                        })
                    }}</span></label
                >
            </div>

            <ValidationError :formModel="v$.minutes" type="required" :message="t('gift_card.minutes_val_required')" />
        </div>

        <p class="fw-bold text-start">{{ t('gift_card.reciever_phone') }}</p>
        <div class="mb-4">
            <TextInput
                :field="v$.receiverPhoneNumber"
                :inputmode="'numeric'"
                :pattern="'[0-9]*'"
                :placeholder="t('gift_card.reciever_phone_hint')"
                :autocomplete="'tel-national'"
                :disabled="disableForm"
            />

            <ValidationErrors :formModel="v$.receiverPhoneNumber" />
        </div>

        <p class="fw-bold text-start">{{ t('gift_card.email') }}</p>
        <div class="mb-4">
            <TextInput :field="v$.email" :placeholder="t('gift_card.email_hint')" :disabled="disableForm" />
            <ValidationError :formModel="v$.email" type="required" :message="t('gift_card.email_val_required')" />
            <ValidationError :formModel="v$.email" type="email" :message="t('gift_card.email_val_invalid')" />

            <div v-if="!newsletterAccepted" class="mt-1 text-start">
                <CheckboxInput
                    :field="v$.newsletterConsent"
                    :label="t('gift_card.subscribe_to_newsletter')"
                    :id="newsletterCheckbox"
                    :disabled="disableForm"
                />
            </div>
        </div>

        <button class="btn btn-primary msd-form-submit" :disabled="disableForm">
            {{ t('gift_card.submit') }}
        </button>

        <p class="fw-bold text-start mt-4 mb-2">{{ t('gift_card.terms') }}</p>
        <p class="text-start">{{ t('gift_card.terms_text') }}</p>
    </form>
    <div v-if="!disableForm" class="mt-3 mb-3">
        <img :src="postPaymentEngineLogo" class="msd-payment-logo" />
    </div>
</template>

<script setup>
    import { computed, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import useVuelidate from '@vuelidate/core';
    import { required, numeric, email, not, sameAs, helpers } from '@vuelidate/validators';
    import TextInput from '../../components/VuelidateForms/TextInput.vue';
    import CheckboxInput from '../../components/VuelidateForms/CheckboxInput.vue';
    import ValidationError from '../../components/VuelidateForms/ValidationError.vue';
    import ValidationErrors from '../../components/VuelidateForms/ValidationErrors.vue';
    import Spinner from '../../components/Spinner.vue';
    import ErrorMessage from '../../components/ErrorMessage.vue';
    import phoneFormats from '../../utility/phone-formats';

    const props = defineProps({
        disableForm: { type: Boolean, required: false, default: false }
    });
    const emit = defineEmits(['onSubmit']);

    const store = useStore();
    const { t } = useI18n();

    const TARIFFS = store.getters['pageGiftCardOverview/TARIFFS'];
    const APP_LOCALE = store.getters['appSettings/APP_LOCALE'];
    const PHONE_NUMBER = store.getters['authStorage/PHONE_NUMBER'];

    const INITIATING_PAYMENT = computed(() => store.getters['pageGiftCardOverview/INITIATING_PAYMENT']);
    const INITIATION_ERROR = computed(() => store.getters['pageGiftCardOverview/INITIATION_ERROR']);
    const postPaymentEngineLogo = store.getters['appSettings/POST_PAYMENT_ENGINE_LOGO'];
    const newsletterAccepted = store.getters['customerPreferences/NEWSLETTER_CONSENT'];

    const formValues = reactive({
        minutes: store.getters['pageGiftCardOverview/DEFAULTTARIFF'],
        receiverPhoneNumber: '',
        email: store.getters['customerPreferences/RECENT_MAILBOX'],
        newsletterConsent: !newsletterAccepted
    });
    const validationRules = computed(() => ({
        minutes: { required },
        receiverPhoneNumber: {
            required: helpers.withMessage(t('gift_card.reciever_phone_val_required'), required),
            numeric: helpers.withMessage(t('gift_card.reciever_phone_val_invalid'), numeric),
            localPhone: helpers.withMessage(
                t('gift_card.reciever_phone_val_local_phone_format'),
                phoneFormats.GetLocalPhoneRule(APP_LOCALE)
            ),
            notSameAsLoggedIn: helpers.withMessage(
                t('gift_card.reciever_phone_val_not_same_as_logged_in'),
                not(sameAs(PHONE_NUMBER))
            )
        },
        email: { required, email },
        newsletterConsent: {}
    }));
    const v$ = useVuelidate(validationRules, formValues);

    const submitForm = () => {
        v$.value.$touch();
        if (!v$.value.$invalid) {
            emit('onSubmit', formValues);
        }
    };
</script>
