<template>
    <div id="dintero_container">
        <h1>{{ heading }}</h1>
        <div ref="el_container"></div>
    </div>
</template>

<script setup>
    // https://github.com/Dintero/Dintero.Checkout.Web.SDK
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { embed } from '@dintero/checkout-web-sdk';
    import timeout from '../../utility/timeout';
    import getDinteroLocale from './dintero-locales';

    const props = defineProps({
        paymentId: {
            type: String,
            required: true
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        scrollIntoView: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const store = useStore();

    const DINTERO_LOCALE = getDinteroLocale(store.getters['appSettings/APP_LOCALE']);

    const el_container = ref(null);

    onMounted(() => {
        embed({
            container: el_container.value,
            sid: props.paymentId,
            language: DINTERO_LOCALE
        })
            .then(() => timeout(1000)) // some "safe" value until iframe content is rendered so sroll has enough room to occur
            .then(() => props.scrollIntoView && document.getElementById('dintero_container').scrollIntoView());
    });
</script>
