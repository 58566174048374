<template>
    <div class="page-wrapper">
        <Spinner v-if="TARIFFS_LOADING" :fullScreen="true" />

        <PageSection v-else>
            <div class="col-md-8 offset-md-2">
                <h1>{{ t('gift_card.title') }}</h1>
                <p>{{ t('gift_card.sub_title') }}</p>
            </div>

            <div v-if="!!PAYMENT_ERROR" class="col-md-8 offset-md-2">
                <p v-if="PAYMENT_ERROR == 'authorization'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('gift_card.error_authorization') }}
                    {{ t('gift_card.error_try_again') }}
                </p>
                <p v-if="PAYMENT_ERROR == 'cancelled'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('gift_card.error_cancelled') }}
                    {{ t('gift_card.error_try_again') }}
                </p>
                <p v-if="PAYMENT_ERROR == 'capture'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('gift_card.error_capture') }}
                    {{ t('gift_card.error_try_again') }}
                </p>
                <p v-if="PAYMENT_ERROR == 'failed'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('gift_card.error_failed') }}
                    {{ t('gift_card.error_try_again') }}
                </p>
            </div>
            <GiftCardPaymentInitiationForm @onSubmit="INITIATE_PAYMENT($event)" :disableForm="!!DINTERO_PAYMENT_ID" />
        </PageSection>

        <PageSection v-if="DINTERO_PAYMENT_ID">
            <DinteroEmbeddedCheckout
                :paymentId="DINTERO_PAYMENT_ID"
                :heading="t('gift_card.checkout_title')"
                :scrollIntoView="true"
            />
        </PageSection>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import Spinner from '../../components/Spinner.vue';
    import GiftCardPaymentInitiationForm from './GiftCardPaymentInitiationForm.vue';
    import DinteroEmbeddedCheckout from '../../components/DinteroEmbeddedCheckout/DinteroEmbeddedCheckout.vue';
    import PageSection from '../../components/PageSection.vue';

    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    store.dispatch('pageGiftCardOverview/ON_PAGE_LOAD');
    store.dispatch('pageGiftCardOverview/LOAD_TARIFFS');

    const TARIFFS_LOADING = computed(() => store.getters['pageGiftCardOverview/LOADING']);
    const DINTERO_PAYMENT_ID = computed(() => store.getters['pageGiftCardOverview/DINTERO_PAYMENT_ID']);
    const CCID = computed(() => store.getters['appStateStorage/CCID']);

    const INITIATE_PAYMENT = ({ email, minutes, receiverPhoneNumber, newsletterConsent }) => {
        let ccid = CCID.value;
        store.dispatch('pageGiftCardOverview/INITIATE_PAYMENT', { email, minutes, receiverPhoneNumber, ccid });
        store.dispatch('customerPreferences/UPDATE_CUSTOMER_PREFERENCES', { email, newsletterConsent });
    };

    const PAYMENT_ERROR = computed(() => route.query.error || '');
</script>
