// an ISO 639-1 two-letter language code
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes

const getDinteroLocale = (appLocale) => {
    switch (appLocale) {
        case 'Uk':
            return 'en';

        case 'Dk':
            return 'da';

        case 'No':
            return 'nb';

        case 'Sv':
            return 'sv';

        default:
            return 'en';
    }
};

export default getDinteroLocale;
